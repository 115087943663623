<template>
  <prism-editor
    class="my-editor py-5"
    v-model="updatedHtml"
    :highlight="highlighter"
    :placeholder="placeholder"
    :disabled="disabled"
    :readonly="readonly"
    :style="`max-height: ${maxHeight}; min-height: ${minHeight};`"
    :line-numbers="lineNumbers"
    insert-spaces
    word-wrap
    theme="vs-code-dark"
    :tab-size="4"
    language="html"
    focus
  />
</template>

<script>
import { mapGetters } from "vuex";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";

export default {
  name: "HtmlEditor",

  props: {
    disabled: { type: Boolean, default: false },
    html: { type: String, required: true },
    lineNumbers: { type: Boolean, default: true },
    maxHeight: { type: String, default: "400px" },
    minHeight: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    readonly: { type: Boolean, default: false },
  },

  components: {
    PrismEditor,
  },

  data() {
    return {
      editedHtml: "",
    };
  },

  computed: {
    ...mapGetters([
      "editorLayout",
      "token",
      "company",
      "isConecta",
      "loading",
      "users",
      "getUpdatedAtTemplate",
    ]),

    updatedHtml: {
      get() {
        return this.editedHtml;
      },
      set(newVal) {
        this.editedHtml = newVal;
      },
    },
  },

  watch: {
    updatedHtml() {
      this.$emit("update", this.updatedHtml);
    },
  },

  methods: {
    highlighter(code) {
      return highlight(code, languages.js);
    },
  },

  beforeMount() {
    this.editedHtml = this.html;
  },
};
</script>
<style scoped>
/* required class */
.my-editor {
  background: #f9f9f9;
  color: #444;
  font-family: Roboto, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
